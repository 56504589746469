// node_modules
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash.debounce";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
// Enums
import {
  LinkStatusEnum,
  ObjectTypeEnum,
  OrderByEnum,
  SavedDocumentTypeEnum,
  SortTypeEnum,
  StudyStatusEnum,
  StudyTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Components
import {
  Dropdown,
  MainTitle,
  MarkdownItComponent,
  ObjectDetails,
  TextAreaModal,
  TextBoxModal,
} from "Components";
// Contexts
import {
  AuthContext,
  CollaborationContext,
  EditorContext,
  ElementVisibilityContext,
  PinnedContext,
} from "Providers";
// Styles
import commonDropdownStyles from "Styles/Common/dropdown.module.scss";
import entityLikeCardStyles from "Styles/entityLikeCard.module.scss";
// Custom hooks
import { useFetch, useStudyNameChangeListener } from "Hooks";
// Types
import { TDocumentsDTO, TOption, TOptions, TUseFetch } from "Types";
// Controllers
import {
  ImageControllerSingleton,
  LinkingControllerSingleton,
  SavedDocumentControllerSingleton,
  StudyControllerSingleton,
  TemplateControllerSingleton,
} from "Controllers";
// Helpers
import {
  DocumentTypeHelperSingleton,
  LogHelperSingleton,
  MarkdownItHelperSingleton,
  ObjectTypeHelperSingleton,
  ProseMirrorHelperSingleton,
  StudyStatusHelperSingleton,
  StudyTypeHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Constants
import {
  EditorConstants,
  EventConstants,
  GeneralConstants,
  StudyConstants,
} from "Constants";
// Interfaces
import { ISavedDocumentDTO, IStudyDTO } from "Interfaces";

type TStudyDetailsProps = {
  id?: string;
  doIgnoreIsDeleted?: boolean;
  hideReferenceModal?: (callback?: () => void) => void;
};

export const StudyDetails: FC<TStudyDetailsProps> = ({
  id: studyIdFromProps,
  doIgnoreIsDeleted,
  hideReferenceModal,
}: TStudyDetailsProps) => {
  // Hooks
  const { studyId: studyIdFromParams } = useParams();
  const navigate = useNavigate();

  // Constants
  const studyId: string | undefined = studyIdFromProps ?? studyIdFromParams;

  // Context
  const { isUserExternal } = useContext(AuthContext);
  const { editorMenuProps, forceUpdateEditorViewSource } =
    useContext(EditorContext);
  const { refreshPins } = useContext(PinnedContext);
  const { isEditModeOn, isEditorShown } = useContext(CollaborationContext);
  const { canUserEdit } = useContext(ElementVisibilityContext);

  // State
  const [currentStudy, setCurrentStudy] = useState<IStudyDTO | undefined>(
    undefined
  );
  const [isConclusionModalOpen, setIsConclusionModalOpen] =
    useState<boolean>(false);
  const [allStudyTypesDropdownOptions, setAllStudyTypesDropdownOptions] =
    useState<TOptions<StudyTypeEnum>[]>([]);
  const [isCustomStudyTypeModalOpen, setIsCustomStudyTypeModalOpen] =
    useState<boolean>(false);

  // Memoized axios parameters
  const axiosParameters = useMemo(() => {
    return { doIgnoreIsDeleted: doIgnoreIsDeleted ? doIgnoreIsDeleted : false };
  }, [doIgnoreIsDeleted]);

  // Memoized is editable
  const isEditable = useMemo(() => {
    return !isUserExternal && !!isEditModeOn && isEditorShown && canUserEdit;
  }, [isUserExternal, isEditModeOn, isEditorShown, canUserEdit]);

  // Retrieve the selected study
  const { fetchedData: fetchedStudy }: TUseFetch<IStudyDTO> = useFetch(
    `api/study/${studyId}`,
    axiosParameters
  );

  // Logic
  useEffect(() => {
    if (fetchedStudy) {
      setCurrentStudy(fetchedStudy);
    }
  }, [fetchedStudy]);

  const refreshCustomStudyTypesAsync = useCallback(async () => {
    const allStudyTypeDropdownOptionsGroups =
      await StudyTypeHelperSingleton.getCustomTypeDropdownOptionsGroupAsync(
        true,
        true
      );
    setAllStudyTypesDropdownOptions(allStudyTypeDropdownOptionsGroups);
  }, []);

  useEffect(() => {
    refreshCustomStudyTypesAsync();
  }, [refreshCustomStudyTypesAsync]);

  const handleNewStudyDescriptionAsync = useCallback(
    async (
      study: IStudyDTO | undefined,
      canEdit: boolean,
      newDescriptionValue: string
    ): Promise<void> => {
      // if the user can not edit or the current study is not set yet then do nothing
      if (!canEdit || !study) {
        return;
      }

      // just remove remove me tags
      let newDescriptionValueCopy = `${newDescriptionValue}`;
      newDescriptionValueCopy = newDescriptionValueCopy.replaceAll(
        `${EditorConstants.OPEN_REMOVE_ME_TAG}`,
        ""
      );
      newDescriptionValueCopy = newDescriptionValueCopy.replaceAll(
        `${EditorConstants.CLOSE_REMOVE_ME_TAG}`,
        ""
      );

      // create new study object with updated description
      const newStudy: IStudyDTO = {
        ...study,
        description: newDescriptionValueCopy,
      };

      // update study
      setCurrentStudy((oldCurrentStudy) => {
        // safety-checks
        if (!oldCurrentStudy) {
          return oldCurrentStudy;
        }

        // update current study description
        return {
          ...oldCurrentStudy,
          description: newDescriptionValueCopy,
        };
      });

      // need to post process markdown in order to remove everything between the remove me tags (including the tags)
      newDescriptionValue =
        MarkdownItHelperSingleton.postProcessMarkdown(newDescriptionValue);
      newStudy.description = newDescriptionValue;

      // update study in database
      await StudyControllerSingleton.updateAsync(newStudy);

      // log
      LogHelperSingleton.log("UpdateStudyDescription");
    },
    []
  );

  // debounce the handleNewStudyDescriptionAsync function
  const debouncedHandleNewStudyDescriptionAsync = useMemo(
    () =>
      debounce(
        handleNewStudyDescriptionAsync,
        GeneralConstants.DEFAULT_MS_DELAY
      ),
    [handleNewStudyDescriptionAsync]
  );

  const onDeleteClickAsync = useCallback(
    async (study: IStudyDTO | undefined, canEdit: boolean): Promise<void> => {
      // if the user can not edit or the current study is not set yet then do nothing
      if (!canEdit || !study || !study.id) {
        return;
      }

      // check if the user really wants to delete the study
      if (!confirm(StudyConstants.DELETE_STUDY_CONFIRMATION)) return;

      if (hideReferenceModal) {
        hideReferenceModal(async () => await deleteStudyAsync(study.id));
      } else {
        await deleteStudyAsync(study.id);
      }
    },
    [navigate, refreshPins]
  );

  const deleteStudyAsync = useCallback(async (studyId: string) => {
    // delete the current study in the database
    const isSuccess = await StudyControllerSingleton.deleteAsync(studyId);

    // Indicate it to the user if the study could not be deleted
    if (!isSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not delete study."
      );
      return;
    }

    // refresh the pins to make sure deleted pins are gone
    await refreshPins();
  }, []);

  const handleNewStudyTitleAsync = useCallback(
    async (
      study: IStudyDTO | undefined,
      canEdit: boolean,
      newTitle: string
    ): Promise<void> => {
      // if the user can not edit or the current study is not set yet then do nothing
      if (!canEdit || !study) {
        return;
      }

      // change the title of the current study
      const newStudy: IStudyDTO = {
        ...study,
        title: newTitle,
      };

      // update study
      setCurrentStudy({
        ...newStudy,
      });

      // update study in database
      const isSuccess = await StudyControllerSingleton.updateAsync(newStudy);

      // indicate it to the user if the title could not be updated
      if (!isSuccess) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not update title of study."
        );
      }

      // log
      LogHelperSingleton.log("UpdateStudyTitle");
    },
    []
  );

  // debounce the handleNewStudyTitleAsync function
  const debouncedHandleNewStudyTitleAsync = useMemo(
    () =>
      debounce(
        handleNewStudyTitleAsync,
        EventConstants.UPDATE_OBJECT_NAME_DEFAULT_MS_DELAY
      ),
    [handleNewStudyTitleAsync]
  );

  // Logic
  const onConclusionSavedAsync = useCallback(
    async (
      study: IStudyDTO | undefined,
      canEdit: boolean,
      newConclusion: string
    ): Promise<void> => {
      // if the user can not edit or the current study is not set yet then do nothing
      if (!canEdit || !study) {
        return;
      }

      // add the conclusion to the current study and change the status to closed
      const newStudy: IStudyDTO = {
        ...study,
        status: StudyStatusEnum.Closed,
        conclusion: newConclusion,
      };

      // update study in database
      const isSuccess = await StudyControllerSingleton.updateAsync(newStudy);
      // indicate it to the user if the conclusion could not be updated
      if (!isSuccess) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not update status of study."
        );
        return;
      }

      // update the current study in the browser and close the modal
      setCurrentStudy(newStudy);
      setIsConclusionModalOpen(false);

      // log
      LogHelperSingleton.log("AddConclusionToStudy");
    },
    []
  );

  const refreshStudyDocumentsAsync = useCallback(
    async (
      study: IStudyDTO | undefined,
      fromDate: Date | undefined,
      filterOptions: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[],
      sortType: SortTypeEnum,
      callback?: (newSavedDocuments: ISavedDocumentDTO[]) => void
    ): Promise<void> => {
      // if the current study is not set yet then do nothing
      if (!study || !study.id) {
        return;
      }

      // get study saved documents
      const newSavedDocuments: TDocumentsDTO =
        await SavedDocumentControllerSingleton.getObjectSavedDocumentsAsync(
          study.id,
          ObjectTypeEnum.Study,
          sortType === SortTypeEnum.Oldest
            ? OrderByEnum.Ascending
            : OrderByEnum.Descending,
          fromDate,
          DocumentTypeHelperSingleton.getSelectedFilterOptions(filterOptions)
        );

      // update study saved documents in context
      setCurrentStudy({
        ...study,
        savedDocuments: [...newSavedDocuments.documents],
      });

      // if callback is defined then call it
      if (callback) {
        callback(newSavedDocuments.documents);
      }
    },
    []
  );

  const refreshDocumentsAsync = useCallback(
    async (
      fromDate: Date | undefined,
      selectedFilterOptions: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[],
      sortType: SortTypeEnum,
      callback?: ((newSavedDocuments: ISavedDocumentDTO[]) => void) | undefined
    ): Promise<void> => {
      // safety-checks
      if (!currentStudy) {
        // do nothing, return;
        return;
      }

      // call refreshStudyDocumentsAsync
      await refreshStudyDocumentsAsync(
        currentStudy,
        fromDate,
        selectedFilterOptions,
        sortType,
        callback
      );
    },
    [currentStudy, refreshStudyDocumentsAsync]
  );

  const updateStatusAsync = useCallback(
    async (
      study: IStudyDTO | undefined,
      canEdit: boolean,
      value: TOption<StudyStatusEnum>
    ): Promise<void> => {
      // if the user can not edit or the current study is not set yet then do nothing
      if (!canEdit || !study) {
        return;
      }

      // if the value did not really change then do nothing
      if (value.value === study.status) return;
      if (value.value === StudyStatusEnum.Closed) {
        setIsConclusionModalOpen(true);
        return;
      }

      // change the status of the current study
      const newStudy: IStudyDTO = {
        ...study,
        status: value.value,
      };

      // update study in database
      const isSuccess = await StudyControllerSingleton.updateAsync(newStudy);
      if (!isSuccess) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not update status of study."
        );
        return;
      }

      // update the current study in the browser
      setCurrentStudy(newStudy);

      // log
      LogHelperSingleton.log("ChangeStudyStatus");
    },
    []
  );

  const convertToEntityAsync = useCallback(
    async (study: IStudyDTO | undefined): Promise<void> => {
      // if the current study is not set yet then do nothing
      if (!study || !study.id) {
        return;
      }

      // convert the study to an entity
      const isSuccess = await StudyControllerSingleton.convertToEntityAsync(
        study.id
      );
      if (!isSuccess) {
        // indicate it to the user if the study could not be converted to an entity
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not convert study to entity."
        );
        return;
      }

      // log
      LogHelperSingleton.log("ConvertStudyToEntity");

      // refresh the pins
      await refreshPins();

      // redirect the user to the entity page
      navigate(`/library/entities/${study.id}`);
    },
    [navigate, refreshPins]
  );

  const getStudyTypeTemplateAsync = useCallback(
    async (
      study: IStudyDTO | undefined,
      oldType: StudyTypeEnum,
      newType: StudyTypeEnum
    ): Promise<void> => {
      // if the current study is not set yet then do nothing
      if (!study) {
        return;
      }

      // if the description contains content then do nothing
      if (!ProseMirrorHelperSingleton.isSourceEmpty(study.description)) {
        // retrieve the template assiocated with the old entity type
        const template =
          await TemplateControllerSingleton.getStudyTemplateAsync(oldType);

        // check if the source is exactly the old type template
        if (template !== study.description) return;
      }

      // retrieve the template assiocated with the new entity type
      const template =
        await TemplateControllerSingleton.getStudyTemplateAsync(newType);
      // if there is no template do nothing
      if (!template) return;

      // create new study
      const newStudy: IStudyDTO = {
        ...study,
        type: newType,
        description: template,
      };

      // update the description with the template
      setCurrentStudy({
        ...newStudy,
      });

      // update description in database
      await StudyControllerSingleton.updateAsync(newStudy);

      // update editor view source
      forceUpdateEditorViewSource(template);
    },
    [forceUpdateEditorViewSource]
  );

  const saveTypeChangeAsync = useCallback(
    async (
      study: IStudyDTO | undefined,
      newType: StudyTypeEnum,
      customTypeName?: string
    ): Promise<void> => {
      // if the current study is not set yet then do nothing
      if (!study || !study.id) {
        return;
      }

      // update type in database
      await StudyControllerSingleton.updateTypeAsync(
        study.id,
        newType,
        customTypeName
      );

      // update type
      setCurrentStudy({
        ...study,
        type: newType,
        customTypeName,
      });

      // refresh the custom study types
      await refreshCustomStudyTypesAsync();
    },
    [refreshCustomStudyTypesAsync]
  );

  const updateTypeAsync = useCallback(
    async (
      study: IStudyDTO | undefined,
      canEdit: boolean,
      option: TOption<StudyTypeEnum>
    ): Promise<void> => {
      // if the user can not edit or the current study is not set yet then do nothing
      if (!canEdit || !study) {
        return;
      }

      // if create custom type is selected then prompt the user for the custom type name
      if (option.title === StudyConstants.CREATE_CUSTOM_TYPE_OPTION) {
        setIsCustomStudyTypeModalOpen(true);
        return;
      } else if (option.title === StudyConstants.CONVERT_TO_ENTITY_OPTION) {
        convertToEntityAsync(study);
        return;
      }

      // check if a custom type is selected
      const customTypeName: string | undefined =
        option.value === StudyTypeEnum.Custom ? option.title : undefined;

      // save the types changes
      const oldStudyType = study.type;
      await saveTypeChangeAsync(study, option.value, customTypeName);
      await getStudyTypeTemplateAsync(study, oldStudyType, option.value);

      // log
      LogHelperSingleton.log("ChangeStudyType");
    },
    [convertToEntityAsync, getStudyTypeTemplateAsync, saveTypeChangeAsync]
  );

  const onImageSubmittedAsync = useCallback(
    async (
      study: IStudyDTO | undefined,
      canEdit: boolean,
      image: File,
      caption?: string
    ): Promise<void> => {
      // if the user can not edit or the current study is not set yet then do nothing
      if (!canEdit || !study || !study.id) {
        return;
      }

      // upload the chosen image to the server and add it to the current study
      const newImage = await ImageControllerSingleton.addImageToObjectAsync(
        image,
        study.id,
        ObjectTypeEnum.Study,
        caption
      );
      if (!newImage) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not add image to study."
        );
        return;
      }

      // update the current study to include the new image
      setCurrentStudy({
        ...study,
        images: [...study.images, newImage],
      });

      editorMenuProps.applyInsertImage(
        newImage.id,
        newImage.path,
        newImage.caption,
        ""
      );
    },
    [editorMenuProps]
  );

  const deleteDocumentAsync = useCallback(
    async (
      study: IStudyDTO | undefined,
      canEdit: boolean,
      documentsToDelete: ISavedDocumentDTO[]
    ): Promise<void> => {
      // if the user can not edit or the current study is not set yet then do nothing
      if (!canEdit || !study || !study.id) {
        return;
      }

      // unlink the selected document from the current study
      const isSuccess = await LinkingControllerSingleton.deleteBulkAsync(
        study.id,
        ObjectTypeEnum.Study,
        documentsToDelete.map((document) => document.id),
        ObjectTypeEnum.Document
      );

      // safety-checks
      if (!isSuccess) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not delete selected documents."
        );
        return;
      }

      // log
      LogHelperSingleton.log("RemoveDocument(s)FromStudy");
    },
    []
  );

  const onCreateCustomEntityTypeAsync = useCallback(
    async (
      study: IStudyDTO | undefined,
      customTypeName: string
    ): Promise<void> => {
      // if the current study is not set yet then do nothing
      if (!study) {
        return;
      }

      // check if the custom entity type has a value
      if (customTypeName.trim().length === 0) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Please provide a value for the custom type name."
        );
        return;
      }

      // log
      LogHelperSingleton.log("CustomStudyType-CreateFromDropdown");

      // save the types changes including the custom type name
      await saveTypeChangeAsync(study, StudyTypeEnum.Custom, customTypeName);

      // close the create entity type modal
      setIsCustomStudyTypeModalOpen(false);
    },
    [saveTypeChangeAsync]
  );

  // Hooks live update the Studies name
  useStudyNameChangeListener(undefined, setCurrentStudy);

  // if the study id is not defined then navigate to the studies list
  if (!studyId) {
    navigate("/library/studies/");
    return null;
  }

  // if the study is not fetched then show nothing
  if (!currentStudy) return <div></div>;

  return (
    <ObjectDetails
      objectType={ObjectTypeEnum.Study}
      object={currentStudy}
      setObject={setCurrentStudy}
      type="Study"
      onDeleteClickAsync={() => onDeleteClickAsync(currentStudy, isEditable)}
      onImageSubmittedAsync={(image: File, caption?: string) =>
        onImageSubmittedAsync(currentStudy, isEditable, image, caption)
      }
      noSourcePlaceholder="Welcome to this page! Here you can create and organize this page’s content by adding headers, format the text and add images from the top bar. From the right sidebar you can add references, highlights and images from linked documents. Have fun creating!"
      onSourceChange={(newValue: string) =>
        debouncedHandleNewStudyDescriptionAsync(
          currentStudy,
          isEditable,
          newValue
        )
      }
      onForceSourceChange={(newValue: string) => {
        setCurrentStudy({ ...currentStudy, description: newValue });
      }}
      refreshDocumentsAsync={refreshDocumentsAsync}
      deleteSavedDocumentAsync={
        !isEditable
          ? undefined
          : (savedDocumentsToDelete: ISavedDocumentDTO[]) =>
              deleteDocumentAsync(
                currentStudy,
                isEditable,
                savedDocumentsToDelete
              )
      }
    >
      <div
        className={`${entityLikeCardStyles.entityLikeCardHeaderContainer} ${entityLikeCardStyles.hasBottomContent}`}
      >
        <div
          className={
            entityLikeCardStyles.entityLikeCardHeaderContainerTopContent
          }
        >
          <Dropdown
            isEditable={isEditable}
            selectedOption={{
              value: currentStudy.type,
              title: StudyTypeHelperSingleton.getStudyTypeDisplayName(
                currentStudy.type,
                currentStudy.customTypeName
              ),
            }}
            handleOptionSelect={(option: TOption<StudyTypeEnum>) =>
              updateTypeAsync(currentStudy, isEditable, option)
            }
            options={allStudyTypesDropdownOptions}
            placeholderText="Select study type"
            className={commonDropdownStyles.commonDropdown}
            classNameSelect={`${commonDropdownStyles.grayDropdownSelect} ${entityLikeCardStyles.objectTypeDropdown}`}
            leftIconProps={{
              icon: ObjectTypeHelperSingleton.getObjectTypeIcon(
                ObjectTypeEnum.Study
              ),
              className: `${entityLikeCardStyles.objectTypeIcon} ${entityLikeCardStyles[ObjectTypeEnum.Study]}`,
            }}
          />
          <Dropdown
            isEditable={isEditable}
            selectedOption={{
              value: currentStudy.status,
              title: StudyStatusHelperSingleton.getStudyStatusDisplayName(
                currentStudy.status
              ),
            }}
            handleOptionSelect={(option: TOption<StudyStatusEnum>) =>
              updateStatusAsync(currentStudy, isEditable, option)
            }
            options={
              StudyStatusHelperSingleton.studyStatusFilterDropdownOptions
            }
            className={[
              commonDropdownStyles.commonDropdown,
              commonDropdownStyles.rightAligned,
            ].join(" ")}
            classNameSelect={`${commonDropdownStyles.grayDropdownSelect} ${entityLikeCardStyles.objectStatusDropdown}`}
            leftIconProps={{
              icon: faCircle,
              className: `${entityLikeCardStyles.objectStatusIcon} ${entityLikeCardStyles[StudyStatusHelperSingleton.getStudyStatusDisplayName(currentStudy.status).toLowerCase()]}`,
            }}
          />
        </div>
        <MainTitle
          showFullTitleOnHoverOnTooltip
          title={currentStudy.title}
          isEditable={isEditable}
          onUpdateTitle={(newTitle: string) =>
            debouncedHandleNewStudyTitleAsync(
              currentStudy,
              isEditable,
              newTitle
            )
          }
        />
      </div>
      {currentStudy.conclusion && currentStudy.conclusion.length > 0 && (
        <div className={entityLikeCardStyles.entityLikeCardContentContainer}>
          <div
            className={entityLikeCardStyles.entityLikeCardInformationContainer}
          >
            <div className={entityLikeCardStyles.conclusion}>
              <h3 className={entityLikeCardStyles.conclusionTitle}>
                Conclusion
              </h3>
              <MarkdownItComponent
                source={currentStudy.conclusion}
                noSourcePlaceholder={"Conclusion"}
              />
            </div>
          </div>
        </div>
      )}
      <TextAreaModal
        isOpen={isConclusionModalOpen}
        setIsOpen={setIsConclusionModalOpen}
        textName="Conclusion"
        title="Write your conclusion"
        placeHolder="Write your conclusion here..."
        onSaveButtonClick={(textValue: string) =>
          onConclusionSavedAsync(currentStudy, isEditable, textValue)
        }
      />
      <TextBoxModal
        isOpen={isCustomStudyTypeModalOpen}
        setIsOpen={setIsCustomStudyTypeModalOpen}
        onSaveButtonClick={(textValue: string) =>
          onCreateCustomEntityTypeAsync(currentStudy, textValue)
        }
        placeHolder={"Custom study type name"}
        textName="Custom study type name"
        title="Create custom study type"
      />
    </ObjectDetails>
  );
};
