// React
import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
// Types
import { TAskAIAssistantRequirement, TLineChat } from "Types";
// Components
import { TextLineChat } from "../../../Chat/TextLineChat/TextLineChat";
import { AskAIAssistantRequirementsLineChat } from "./AskAIAssistantRequirementsLineChat";
import { AskAIAssistantSettings } from "./AskAIAssistantSettings";
// Styles
import styles from "./askAIAssistantLineChats.module.scss";
// Enums
import { AskAIAssistantMenuItemEnum, ObjectTypeEnum } from "Enums";
// icons
import { faGlobe, faFile } from "@fortawesome/free-solid-svg-icons";

type TAskAIAssistantLineChatsProps = {
    objectNameEdited: string,
    objectIdEdited: string,
    objectTypeEdited: ObjectTypeEnum,
    selectedMenuItem: AskAIAssistantMenuItemEnum | undefined,
    setSelectedMenuItem: Dispatch<SetStateAction<AskAIAssistantMenuItemEnum | undefined>>,
    isGeneratingText: boolean,
    defaultInput?: string,
    onSubmit: (text: string, documentTypes: ObjectTypeEnum[], dataPointsAmount?: number, requirements?: TAskAIAssistantRequirement[] ) => void
}

export const AskAIAssistantLineChats: FC<TAskAIAssistantLineChatsProps> = ({ objectNameEdited, objectIdEdited, objectTypeEdited, selectedMenuItem, setSelectedMenuItem, isGeneratingText, defaultInput, onSubmit }) => {
    // State
    const [askAIAssistantRequirements, setAskAIAssistantRequirements] = useState<TAskAIAssistantRequirement[]>([]);
    const [dataPointsAmount, setDataPointsAmount] = useState<number | undefined>(10);
    const [documentTypes, setDocumentTypes] = useState<ObjectTypeEnum[]>([]);

    // Memo
    const isUserTableLineChatSubmitDisabled = useMemo((): boolean => {
        // check if two requirement texts are the same in askAIAssistantRequirements
        let doesContainDuplicates = false;
        // loop through askAIAssistantRequirements
        askAIAssistantRequirements.forEach((requirementA: TAskAIAssistantRequirement) => {
            // loop through askAIAssistantRequirements
            askAIAssistantRequirements.forEach((requirementB: TAskAIAssistantRequirement) => {
                // if requirement text is the same as requirement2 text
                if (requirementA.id !== requirementB.id && requirementA.text === requirementB.text) {
                    // set isSameRequirement to true
                    doesContainDuplicates = true;
                }
            });
        });

        // if selectedMenuItem is Table and askAIAssistantRequirements is empty or every requirement is not checked or every requirement text is empty or isSameRequirement is true
        if (selectedMenuItem === AskAIAssistantMenuItemEnum.Table && (!askAIAssistantRequirements || askAIAssistantRequirements.length === 0 || askAIAssistantRequirements
                .every((requirement: TAskAIAssistantRequirement) => !requirement.isChecked) ||
                askAIAssistantRequirements.every((requirement: TAskAIAssistantRequirement) => !requirement.text) ||
                doesContainDuplicates)) {
            // return true
            return true;
        }

        // otherwise, return false
        return false;
    }, [askAIAssistantRequirements, selectedMenuItem]);

    const lineChats = useMemo((): TLineChat[] => {
        // trim object name
        const trimmedObjectName = objectNameEdited.trim();

        // define line chats
        const defaultLineChats: TLineChat[] = [
            {
                text: "Hi there! Where can I help you with?",
                isAskAI: true,
                id: window.crypto.randomUUID()
            },
            {
                id: window.crypto.randomUUID(),
                isAskAI: false,
                hideBorderBottom: true,
                options: [
                    {
                        text: "I have a question for you",
                        onClick: () => { setSelectedMenuItem(AskAIAssistantMenuItemEnum.QuestionAndAnswer); }
                    },
                    {
                        text: "Write a report from the linked documents",
                        onClick: () => { setSelectedMenuItem(AskAIAssistantMenuItemEnum.GenerateReport); }
                    },
                    {
                        text: `Write a general description about "${trimmedObjectName}"`,
                        onClick: () => { setSelectedMenuItem(AskAIAssistantMenuItemEnum.GeneralDescription); }
                    },
                    {
                        text: "Write a section about...",
                        onClick: () => { setSelectedMenuItem(AskAIAssistantMenuItemEnum.WriteSection); }
                    },
                    {
                        text: "Extract detail information from the linked documents",
                        onClick: () => { setSelectedMenuItem(AskAIAssistantMenuItemEnum.Table); }
                    },
                    {
                        text: `Write an executive summary about "${trimmedObjectName}"`,
                        onClick: () => { setSelectedMenuItem(AskAIAssistantMenuItemEnum.ExecutiveSummary); }
                    }
                ]
            }
        ];
        const questionAndAnswerLineChats: TLineChat[] = [
            {
                text: "Ask me any question and I'll try to answer based on your sources.",
                isAskAI: true,
                id: window.crypto.randomUUID(),
                children: <AskAIAssistantSettings
                    settings={{ input: true, documents: true, highlights: true, info: false }}
                    onSettingsChange={(documentTypes: ObjectTypeEnum[], dataPointsAmount?: number) => { setDataPointsAmount(dataPointsAmount); setDocumentTypes([...documentTypes]); }}
                />
            },
            {
                id: window.crypto.randomUUID(),
                isAskAI: false,
                inputPlaceholder: "Enter your question here.",
                isSubmitShown: true,
                isInputShown: true,
                disableSubmitWhenInputEmpty: true,
                inputValue: defaultInput ?? ""
            }
        ];
        const generateReportLineChats: TLineChat[] = [
          {
            id: window.crypto.randomUUID(),
            isAskAI: true,
            text: "I will write a report with an Introduction, Methods, Results, and Conclusion based on your sources.",
            children: (
              <AskAIAssistantSettings
                settings={{ documents: true, info: false }}
                onSettingsChange={(
                  documentTypes: ObjectTypeEnum[],
                  dataPointsAmount?: number
                ) => {
                  setDataPointsAmount(dataPointsAmount);
                  setDocumentTypes([...documentTypes]);
                }}
              />
            ),
          },
          {
            id: window.crypto.randomUUID(),
            isAskAI: false,
            isSubmitShown: true,
          },
        ];
        const generalDescriptionLineChats: TLineChat[] = [
            {
                id: window.crypto.randomUUID(),
                isAskAI: true,
                text: `I will write a general description about "${trimmedObjectName}". Do you want me to write it based on your linked sources or general knowledge?`
            },
            {
                id: window.crypto.randomUUID(),
                isAskAI: false,
                options: [
                    {
                        text: "Use connected documents and their highlights",
                        icon: faFile,
                        onClick: () => { setSelectedMenuItem(AskAIAssistantMenuItemEnum.GeneralDescriptionUsingLinks); }
                    },
                    {
                        text: "Use your general knowledge",
                        icon: faGlobe,
                        onClick: () => { setSelectedMenuItem(AskAIAssistantMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge); }
                    }
                ]
            }
        ];
        const generalDescriptionUsingLinksLineChats: TLineChat[] = [
          {
            id: window.crypto.randomUUID(),
            isAskAI: true,
            text: `I will write a general description about "${trimmedObjectName}" using your sources.`,
            children: (
              <AskAIAssistantSettings
                settings={{
                  documents: true,
                  content: "Yes",
                  highlights: true,
                  info: false,
                }}
                onSettingsChange={(
                  documentTypes: ObjectTypeEnum[],
                  dataPointsAmount?: number
                ) => {
                  setDataPointsAmount(dataPointsAmount);
                  setDocumentTypes([...documentTypes]);
                }}
              />
            ),
          },
          {
            id: window.crypto.randomUUID(),
            isAskAI: false,
            isSubmitShown: true,
          },
        ];
        const generalDescriptionUsingGeneralKnowledgeLineChats: TLineChat[] = [
          {
            id: window.crypto.randomUUID(),
            isAskAI: true,
            text: `I will write a general description about "${trimmedObjectName}" using general knowledge.`,
            children: (
              <AskAIAssistantSettings
                settings={{ info: true }}
                onSettingsChange={() => {
                  setDataPointsAmount(undefined);
                  setDocumentTypes([]);
                }}
              />
            ),
          },
          {
            id: window.crypto.randomUUID(),
            isAskAI: false,
            isSubmitShown: true,
          },
        ];
        const writeSectionLineChats: TLineChat[] = [
          {
            id: window.crypto.randomUUID(),
            isAskAI: true,
            text: "Give me a header and I will write the section.",
            children: (
              <AskAIAssistantSettings
                settings={{ input: true, documents: true, highlights: true, info: false }}
                onSettingsChange={(
                  documentTypes: ObjectTypeEnum[],
                  dataPointsAmount?: number
                ) => {
                  setDataPointsAmount(dataPointsAmount);
                  setDocumentTypes([...documentTypes]);
                }}
              />
            ),
          },
          {
            id: window.crypto.randomUUID(),
            isAskAI: false,
            inputPlaceholder: "Enter the title of the section here.",
            isSubmitShown: true,
            isInputShown: true,
            disableSubmitWhenInputEmpty: true,
            inputValue: defaultInput ?? "",
          },
        ];
        const tableLineChats: TLineChat[] = [
          {
            id: window.crypto.randomUUID(),
            isAskAI: true,
            text: "I will extract detail information from your sources. Please provide me the details you are looking for. Based on the selected object, I will try to add some you may be interested in.",
            children: (
              <AskAIAssistantSettings
                settings={{ documents: false, highlights: false, info: false }}
                onSettingsChange={(
                  documentTypes: ObjectTypeEnum[],
                  dataPointsAmount?: number
                ) => {
                  setDataPointsAmount(undefined);
                  setDocumentTypes([...documentTypes]);
                }}
              />
            ),
          },
          {
            id: window.crypto.randomUUID(),
            isAskAI: false,
            children: (
              <AskAIAssistantRequirementsLineChat
                objectNameEdited={objectNameEdited}
                objectIdEdited={objectIdEdited}
                objectTypeEdited={objectTypeEdited}
                onRequirementsChange={(
                  requirements: TAskAIAssistantRequirement[]
                ) => {
                  setAskAIAssistantRequirements([...requirements]);
                }}
              />
            ),
            isSubmitShown: true,
          },
        ];
        const executiveSummaryLineChats: TLineChat[] = [
          {
            id: window.crypto.randomUUID(),
            isAskAI: true,
            text: `I will write an executive summary about "${trimmedObjectName}" based on the content of the page, and your sources.`,
            children: (
              <AskAIAssistantSettings
                settings={{
                  documents: true,
                  content: "Yes",
                  highlights: true,
                  info: false,
                }}
                onSettingsChange={(
                  documentTypes: ObjectTypeEnum[],
                  dataPointsAmount?: number
                ) => {
                  setDataPointsAmount(dataPointsAmount);
                  setDocumentTypes([...documentTypes]);
                }}
              />
            ),
          },
          {
            id: window.crypto.randomUUID(),
            isAskAI: false,
            isSubmitShown: true,
          },
        ];

        switch (selectedMenuItem) {
            case AskAIAssistantMenuItemEnum.QuestionAndAnswer:
                return questionAndAnswerLineChats;
            case AskAIAssistantMenuItemEnum.GeneralDescription:
                return generalDescriptionLineChats;
            case AskAIAssistantMenuItemEnum.GeneralDescriptionUsingLinks:
                return generalDescriptionUsingLinksLineChats;
            case AskAIAssistantMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge:
                return generalDescriptionUsingGeneralKnowledgeLineChats;
            case AskAIAssistantMenuItemEnum.ExecutiveSummary:
                return executiveSummaryLineChats;
            case AskAIAssistantMenuItemEnum.Table:
                return tableLineChats;
            case AskAIAssistantMenuItemEnum.WriteSection:
                return writeSectionLineChats;
            case AskAIAssistantMenuItemEnum.GenerateReport:
                return generateReportLineChats;
            default:
                return defaultLineChats;
        }
    }, [defaultInput, objectIdEdited, objectNameEdited, objectTypeEdited, setSelectedMenuItem, selectedMenuItem]);

    return (
        <div className={styles.lineChats}>
            {lineChats.map((lineChat: TLineChat) => {
                return (
                    <TextLineChat
                        lineChat={lineChat}
                        isGeneratingText={isGeneratingText}
                        isSubmitDisabled={isUserTableLineChatSubmitDisabled}
                        onSubmit={(text: string) => { onSubmit(text,documentTypes, dataPointsAmount,askAIAssistantRequirements); }}
                        key={lineChat.id} />
                );
            })}
        </div>
    );
};