// node_modules
import { NodeType } from "prosemirror-model";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
// Contexts
import { CollaborationContext, EditorContext, EditorReferencesContext, WebsocketContext } from "Providers";
// Components
import { AskAIAssistantModal } from "Components/Shared/Modals";
import { AskAIPopup } from "./AskAIPopup/AskAIPopup";
import { EditorActionsBar } from "./EditorActionsBar/EditorActionsBar";
import { EditorContent } from "./EditorContent/EditorContent";
import { SelectedTextActionsPopup } from "./SelectedTextActionsPopup/SelectedTextActionsPopup";
// Enums
import { AskAIAssistantMenuItemEnum, ObjectTypeEnum, ToastTypeEnum, TopDepthMarkdownCustomBlockNameEnum } from "Enums";
// Types
import { NullableProseMirrorNode, TCommentDTO, TLogEventName, TOverallTIAutomationResponseDTO, TWriteSectionDTO } from "Types";
// Constants
import { AiConstants, ErrorConstants, TextConstants, WebsocketFunctionNames } from "Constants";
// Helpers
import { AskAIAssistantMenuItemHelperSingleton, LogHelperSingleton, ProseMirrorHelperSingleton, ToastHelperSingleton } from "Helpers";
// Controllers
import { GptControllerSingleton, LinkingControllerSingleton } from "Controllers";
// Hooks
import { useClickOutsideRef } from "Hooks";

type TContextedEditorContentProps = {
    extraClassNames?: { proseMirrorEditor?: string, wysiwygContent?: string }
};

export const ContextedEditorContent: FC<TContextedEditorContentProps> = ({extraClassNames}: TContextedEditorContentProps) => {
    // Context
    const { webSocketController } = useContext(WebsocketContext);
    const { objectIdEdited, objectTypeEdited } = useContext(CollaborationContext);
    const { editorMenuProps, editorRef, editorContainerRef, objectName } = useContext(EditorContext);
    const {
        askAIContainerRef,
        askAIAssistantContainerProps,
        customNodeBlockContainerProps,
        tableBlockContainerProps,
        selectedTextPopupContainerProps,
        commentsPerReferenceId,
        setCommentsPerReferenceId,
        setIsLinkingModalOpen,
        setLinkToName,
        turnBlockReferenceToInlineReference,
        turnInlineReferenceToBlockReference,
    } = useContext(EditorReferencesContext);

    // State
    const [completionId, setCompletionId] = useState<string | undefined>(undefined);
    const [aiGeneratedText, setAIGeneratedText] = useState<string>("");
    const [isGeneratingText, setIsGeneratingText] = useState<boolean>(false);
    const [isAIAssistantModalOpen, setIsAIAssistantModalOpen] = useState<boolean>(false);
    const [selectedModalMenuItem, setSelectedModalMenuItem] = useState<AskAIAssistantMenuItemEnum | undefined>(undefined);

    // Refs
    const abortControllerRef = useRef<AbortController>(new AbortController());

    // Hooks
    useClickOutsideRef(selectedTextPopupContainerProps.ref, () => {
        selectedTextPopupContainerProps.handleOutsideClick();
    });

    const onReferenceCommentsUpdated = useCallback((node: NullableProseMirrorNode | undefined, nodeType: NodeType | undefined, newComments: TCommentDTO[]) => {
        // safety-checks
        if (!node || !nodeType || nodeType.name !== TopDepthMarkdownCustomBlockNameEnum.HighlightReference) {
            return;
        }

        // if it is a highlight reference node
        // get the highlight reference id
        const highlightId: string | undefined = ProseMirrorHelperSingleton
            .getCustomBlockId(node, nodeType);

        // safety-checks
        if (!highlightId) {
            return;
        }

        // init new empty comments per reference id map
        const newCommentsPerReferenceId = new Map<string, TCommentDTO[]>();

        // go through all the comments per reference id
        commentsPerReferenceId.forEach((comments, referenceId) => {
            // if the reference id is the same as the highlight reference id
            if (referenceId === highlightId) {
                // add the comments to the new comments per reference id map
                newCommentsPerReferenceId.set(referenceId, newComments);
            } else {
                // otherwise add the comments to the new comments per reference id map
                newCommentsPerReferenceId.set(referenceId, comments);
            }
        });

        // set the new comments per reference id map
        setCommentsPerReferenceId(newCommentsPerReferenceId);
    }, [commentsPerReferenceId, setCommentsPerReferenceId]);

    const openLinkingModal = useCallback(() => {
        // set is linking modal open to true
        setIsLinkingModalOpen(true);

        // set link to name to the selected text
        setLinkToName(selectedTextPopupContainerProps.selectedText);
    }, [selectedTextPopupContainerProps.selectedText, setIsLinkingModalOpen, setLinkToName]);

    const onCancelCompletion = useCallback(() => {
        // if there is a completion id and selected modal menu item is defined
        if (completionId && selectedModalMenuItem) {
            // invoke cancel completion function
            webSocketController.invokeFunction(
                WebsocketFunctionNames.CancelCompletion,
                completionId,
                [AskAIAssistantMenuItemEnum.QuestionAndAnswer, AskAIAssistantMenuItemEnum.WriteSection, AskAIAssistantMenuItemEnum.GeneralDescriptionUsingLinks, AskAIAssistantMenuItemEnum.ExecutiveSummary, AskAIAssistantMenuItemEnum.Table]
                    .includes(selectedModalMenuItem)
            );

            // set completion id to undefined
            setCompletionId(undefined);
        } else {
            // abort
            abortControllerRef.current.abort();
        }
    }, [completionId, selectedModalMenuItem, webSocketController]);

    // receive completion part handler
    const onReceiveCompletionPart = useCallback((completionPart: string) => {
        // safety-checks
        if(completionPart === null ||
                completionPart === undefined ||
                !completionId) {
            // cancel completion
            onCancelCompletion();

            // set is generating text to false
            setIsGeneratingText(false);

            // stop execution, return
            return;
        }

        // add completion part to ai generated text
        setAIGeneratedText((prevAIGeneratedText) => prevAIGeneratedText + completionPart);
    }, [completionId, onCancelCompletion]);

    // receive completion id handler
    const onReceiveCompletionId = useCallback((newCompletionId: string) => {
        // set completion id
        setCompletionId(newCompletionId);
    }, [setCompletionId]);

    // receive completion result handler
    const onReceiveCompletionResult = useCallback((result: TOverallTIAutomationResponseDTO | string | null | undefined) => {
        // safety-checks
        if (!selectedModalMenuItem) {
            // set ai generated text to error message
            setAIGeneratedText(`${AiConstants.ERROR_START_MESSAGE}${ErrorConstants.AI_GENERATION_NOT_SUPPORTED}`);

            // stop execution, return
            return;
        }

        // set completion id to undefined
        setCompletionId(undefined);

        // set is generating text to false
        setIsGeneratingText(false);

        // safety-checks
        if (!result) {
            // set ai generated text to error message
            setAIGeneratedText(`${AiConstants.ERROR_START_MESSAGE}${AskAIAssistantMenuItemHelperSingleton.getRelatedErrorMessage(selectedModalMenuItem)}`);

            // stop execution, return
            return;
        } else if (typeof result === "string") {
            // otherwise if result is string

            // set ai generated text to string error message
            setAIGeneratedText(`${AiConstants.ERROR_START_MESSAGE}${result}`);

            // stop execution, return
            return;
        }

        // set ai generated text to result text
        setAIGeneratedText(ProseMirrorHelperSingleton.getTIGenerationContent(result, selectedModalMenuItem));
    }, [selectedModalMenuItem]);

    useEffect(() => {
        // add web socket handlers
        webSocketController.addHandler(WebsocketFunctionNames.ReceiveCompletionId, onReceiveCompletionId);
        webSocketController.addHandler(WebsocketFunctionNames.ReceiveCompletionPart, onReceiveCompletionPart);
        webSocketController.addHandler(WebsocketFunctionNames.ReceiveCompletionResult, onReceiveCompletionResult);

        // remove web socket handlers
        return () => {
            webSocketController.removeHandler(WebsocketFunctionNames.ReceiveCompletionId, onReceiveCompletionId);
            webSocketController.removeHandler(WebsocketFunctionNames.ReceiveCompletionPart, onReceiveCompletionPart);
            webSocketController.removeHandler(WebsocketFunctionNames.ReceiveCompletionResult, onReceiveCompletionResult);
        };

    }, [onReceiveCompletionId, onReceiveCompletionPart, onReceiveCompletionResult, webSocketController]);

    const resetAskAIAssistant = useCallback(() => {
        // cancel completion
        onCancelCompletion();

        // set ai generated text to empty string
        setAIGeneratedText("");

        // set is generating text to false
        setIsGeneratingText(false);
    }, [onCancelCompletion]);

    const requestNonWebsocketBasedAssistanceAsync = useCallback(async (text: string, selectedMenuItem: AskAIAssistantMenuItemEnum, documentTypes: ObjectTypeEnum[], dataPointsAmount?: number) => {
        // safety-checks
        if (!objectIdEdited || !objectTypeEdited || !objectName) {
            // stop execution, return
            return;
        }

        // init abort controller
        abortControllerRef.current = new AbortController();

        // if selected menu item is generate report
        if (selectedMenuItem === AskAIAssistantMenuItemEnum.GenerateReport) {
            // generate report from documents and highlights
            const description: string | undefined = await GptControllerSingleton
                .generateReportFromDocumentsAndHighlightsAsync(objectIdEdited, objectTypeEdited, abortControllerRef.current.signal, dataPointsAmount, documentTypes);

            // if description is undefined or null (could be empty string because HTTP call cancelled)
            if (description === undefined || description === null) {
                // set ai generated text to error message
                setAIGeneratedText(`${AiConstants.ERROR_START_MESSAGE}${AskAIAssistantMenuItemHelperSingleton
                    .getRelatedErrorMessage(selectedMenuItem)}`);

                // stop execution, return
                return;
            }

            // otherwise set ai generated text to description
            setAIGeneratedText(description);
        } else if (selectedMenuItem === AskAIAssistantMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge) {
            // generate general description from general knowledge
            const description: string | undefined =
              await GptControllerSingleton.generateDescriptionAsync(
                objectName,
                abortControllerRef.current.signal);

            // if description is undefined or null (could be empty string because HTTP call cancelled)
            if (description === undefined || description === null) {
                // set ai generated text to error message
                setAIGeneratedText(`${AiConstants.ERROR_START_MESSAGE}${AskAIAssistantMenuItemHelperSingleton
                    .getRelatedErrorMessage(selectedMenuItem)}`);

                // stop execution, return
                return;
            }

            // otherwise set ai generated text to description
            setAIGeneratedText(description);
        }
    }, [objectIdEdited, objectName, objectTypeEdited]);

    const requestAssistanceAsync = useCallback(async (text: string, selectedMenuItem: AskAIAssistantMenuItemEnum, documentTypes: ObjectTypeEnum[], dataPointsAmount?: number) => {
        // safety-checks
        if (!objectIdEdited) {
            // stop execution, return
            return;
        }

        // cancel completion
        onCancelCompletion();

        // set is generating text to false
        setIsGeneratingText(false);

        // set selected modal menu item to selected menu item
        setSelectedModalMenuItem(selectedMenuItem);

        // set is ai assistant modal open to true
        setIsAIAssistantModalOpen(true);

        // set ai generated text to empty string
        setAIGeneratedText("");

        // if is generating text
        if(isGeneratingText) {
            // show error toast
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, ErrorConstants.AI_GENERATION_ALREADY_RUNNING);
            // stop execution, return
            return;
        }

        // get do require documents or highlights
        const doRequireDocumentsOrHighlights = AskAIAssistantMenuItemHelperSingleton.getDoRequireDocumentsOrHighlights(selectedMenuItem);

        // if do require documents or highlights
        if (doRequireDocumentsOrHighlights) {
            // get has documents or highlights
            const hasDocumentsOrHighlights = await LinkingControllerSingleton.hasLinksOfTypesAsync(objectIdEdited,
                [ObjectTypeEnum.Highlight, ...documentTypes]);

            // if does not have documents or highlights
            if(!hasDocumentsOrHighlights) {
                // show error toast
                ToastHelperSingleton.showToast(ToastTypeEnum.Error, TextConstants.AI_REQUIRES_DOCUMENTS_OR_HIGHLIGHTS);

                // stop execution, return
                return;
            }
        }

        // set is generating text to true
        setIsGeneratingText(true);

        // get related start log event name
        const startLogEventName: TLogEventName = AskAIAssistantMenuItemHelperSingleton
            .getRelatedStartLogEventName(selectedMenuItem);

        // log
        LogHelperSingleton.log(startLogEventName);

        // set is web socket based
        const isWebsocketBased: boolean = [AskAIAssistantMenuItemEnum.QuestionAndAnswer,
            AskAIAssistantMenuItemEnum.GeneralDescriptionUsingLinks, AskAIAssistantMenuItemEnum.WriteSection,
            AskAIAssistantMenuItemEnum.Table, AskAIAssistantMenuItemEnum.ExecutiveSummary].includes(selectedMenuItem);

        // if is web socket based
        if (isWebsocketBased) {
            // get related web socket function
            const webSocketFunctionName: string = AskAIAssistantMenuItemHelperSingleton
                .getRelatedWebsocketFunctionName(selectedMenuItem);

            // safety-checks
            if (!webSocketFunctionName) {
                // show error toast
                ToastHelperSingleton.showToast(ToastTypeEnum.Error, ErrorConstants.AI_GENERATION_NOT_SUPPORTED);
                // stop execution, return
                return;
            }

            // invoke function
            await webSocketController.invokeFunction(webSocketFunctionName, {
                objectId: objectIdEdited,
                objectType: objectTypeEdited,
                text,
                dataPointsAmount: dataPointsAmount,
                documentTypes: documentTypes
            } as TWriteSectionDTO);
        } else {
            // request non websocket based assistance async
            await requestNonWebsocketBasedAssistanceAsync(
              text,
              selectedMenuItem,
              documentTypes, 
              dataPointsAmount
            );

            // set is generating text to false
            setIsGeneratingText(false);
        }
    }, [isGeneratingText, objectIdEdited, objectTypeEdited, onCancelCompletion, requestNonWebsocketBasedAssistanceAsync, webSocketController]);

    const onAskAIAssistantModalCloseHandler = () => {
        // call on ask ai assistant modal close callback
        askAIAssistantContainerProps.onAskAIAssistantModalCloseCallback();
    };

    const askAIPopupOnClickHandler = () => {
        // set is ai assistant modal open to true
        setIsAIAssistantModalOpen(true);
    };

    // Render
    // WARNING: changing the following tree/components structure might break the logic inside EditorReferencesProvider.ts
    return (
      <div
        ref={editorContainerRef}
        className={
          extraClassNames?.proseMirrorEditor
            ? extraClassNames.proseMirrorEditor
            : ""
        }
      >
        <div style={{ position: "relative" }}>
          <div
            style={{ display: "none" }}
            ref={customNodeBlockContainerProps.blockContainerRef}
          >
            <EditorActionsBar
              blockContainerRef={
                customNodeBlockContainerProps.blockContainerRef
              }
              currentlySelectedNode={
                customNodeBlockContainerProps.currentlySelectedNode
              }
              editorMenuProps={editorMenuProps}
              comments={customNodeBlockContainerProps.comments}
              moveBlockDown={customNodeBlockContainerProps.moveBlockDown}
              moveBlockUp={customNodeBlockContainerProps.moveBlockUp}
              removeBlock={customNodeBlockContainerProps.removeBlock}
              cutBlock={customNodeBlockContainerProps.cutBlock}
              onReferenceCommentsUpdated={onReferenceCommentsUpdated}
              turnBlockReferenceToInlineReference={
                turnBlockReferenceToInlineReference
              }
              turnInlineReferenceToBlockReference={
                turnInlineReferenceToBlockReference
              }
            />
          </div>
          <div
            style={{ display: "none" }}
            ref={tableBlockContainerProps.blockContainerRef}
          >
            <EditorActionsBar
              blockContainerRef={tableBlockContainerProps.blockContainerRef}
              currentlySelectedNode={
                tableBlockContainerProps.currentlySelectedNode
              }
              editorMenuProps={editorMenuProps}
              comments={tableBlockContainerProps.comments}
              moveBlockDown={tableBlockContainerProps.moveBlockDown}
              moveBlockUp={tableBlockContainerProps.moveBlockUp}
              removeBlock={tableBlockContainerProps.removeBlock}
              cutBlock={tableBlockContainerProps.cutBlock}
              onReferenceCommentsUpdated={onReferenceCommentsUpdated}
            />
          </div>
          <div
            style={{ display: "none" }}
            ref={selectedTextPopupContainerProps.ref}
          >
            <SelectedTextActionsPopup
              openLinkingModal={openLinkingModal}
              selectedText={selectedTextPopupContainerProps.selectedText ?? ""}
              objectIdEdited={selectedTextPopupContainerProps.objectIdEdited}
              objectTypeEdited={
                selectedTextPopupContainerProps.objectTypeEdited
              }
              saveAsEntityCallback={
                selectedTextPopupContainerProps.saveAsEntityCallback
              }
              requestAssistanceAsync={async (
                text: string,
                selectedMenuItem: AskAIAssistantMenuItemEnum
              ) => {
                requestAssistanceAsync(text, selectedMenuItem, [ObjectTypeEnum.ScienceArticle, ObjectTypeEnum.UsPatent, ObjectTypeEnum.MagPatent, ObjectTypeEnum.Weblink], 10);
              }}
            />
          </div>
          <div style={{ display: "none" }} ref={askAIContainerRef}>
            <AskAIPopup onClickHandler={askAIPopupOnClickHandler} />
          </div>
          <div>
            <AskAIAssistantModal
              inputText={askAIAssistantContainerProps.inputText}
              aiGeneratedText={aiGeneratedText}
              setAIGeneratedText={setAIGeneratedText}
              isOpen={isAIAssistantModalOpen}
              setIsOpen={setIsAIAssistantModalOpen}
              requestAssistanceAsync={requestAssistanceAsync}
              isGeneratingText={isGeneratingText}
              insertAIGeneratedTextHandler={
                askAIAssistantContainerProps.insertAIGeneratedText
              }
              onCloseCallback={onAskAIAssistantModalCloseHandler}
              onCancel={() => {
                onCancelCompletion();
                setIsGeneratingText(false);
              }}
              selectedMenuItem={selectedModalMenuItem}
              setSelectedMenuItem={setSelectedModalMenuItem}
              resetAskAIAssistant={resetAskAIAssistant}
            />
          </div>
          <EditorContent
            editorRef={editorRef}
            extraClassNames={extraClassNames}
          />
        </div>
      </div>
    );
};