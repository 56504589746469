// Constants
import { WebsocketFunctionNames } from "Constants";
// Enums
import { AskAIAssistantMenuItemEnum } from "Enums";
// Types
import { TLogEventName } from "Types";

export class AskAIAssistantMenuItemHelper {
    public askAIAssistantMenuItemEnumToDisplayString(askAIAssistantMenuItem: AskAIAssistantMenuItemEnum): string {
        switch (askAIAssistantMenuItem) {
            case AskAIAssistantMenuItemEnum.QuestionAndAnswer:
                return "Question & Answer";
            case AskAIAssistantMenuItemEnum.GeneralDescription:
            case AskAIAssistantMenuItemEnum.GeneralDescriptionUsingLinks:
            case AskAIAssistantMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge:
                return "General description";
            case AskAIAssistantMenuItemEnum.ExecutiveSummary:
                return "Executive summary";
            case AskAIAssistantMenuItemEnum.Table:
                return "Extract detail information";
            case AskAIAssistantMenuItemEnum.WriteSection:
                return "Write section";
            case AskAIAssistantMenuItemEnum.GenerateReport:
                return "Report";
            default:
                return "";
        }
    }

    public getRelatedWebsocketFunctionName(askAIAssistantMenuItem: AskAIAssistantMenuItemEnum): string {
        switch (askAIAssistantMenuItem) {
            case AskAIAssistantMenuItemEnum.QuestionAndAnswer:
                return WebsocketFunctionNames.StartQuestionAndAnswerCompletion;
            case AskAIAssistantMenuItemEnum.GeneralDescriptionUsingLinks:
                return WebsocketFunctionNames.StartDescriptionCompletion;
            case AskAIAssistantMenuItemEnum.ExecutiveSummary:
                return WebsocketFunctionNames.StartExecutiveSummaryCompletion;
            case AskAIAssistantMenuItemEnum.WriteSection:
                return WebsocketFunctionNames.StartWriteSectionCompletion;
            case AskAIAssistantMenuItemEnum.Table:
                return WebsocketFunctionNames.StartTableCompletion;
            default:
                return "";
        }
    }

    public getDoRequireDocumentsOrHighlights(askAIAssistantMenuItem: AskAIAssistantMenuItemEnum): boolean {
        switch (askAIAssistantMenuItem) {
            case AskAIAssistantMenuItemEnum.QuestionAndAnswer:
            case AskAIAssistantMenuItemEnum.GeneralDescriptionUsingLinks:
            case AskAIAssistantMenuItemEnum.ExecutiveSummary:
            case AskAIAssistantMenuItemEnum.Table:
            case AskAIAssistantMenuItemEnum.WriteSection:
            case AskAIAssistantMenuItemEnum.GenerateReport:
                return true;
            default:
                return false;
        }
    }

    public getRelatedStartLogEventName(askAIAssistantMenuItem: AskAIAssistantMenuItemEnum): TLogEventName {
        switch (askAIAssistantMenuItem) {
            case AskAIAssistantMenuItemEnum.QuestionAndAnswer:
                return "Start-Gpt-QuestionAndAnswer";
            case AskAIAssistantMenuItemEnum.GeneralDescriptionUsingLinks:
                return "Start-Gpt-GeneralDescriptionUsingLinks";
            case AskAIAssistantMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge:
                return "Start-Gpt-GeneralDescriptionUsingGeneralKnowledge";
            case AskAIAssistantMenuItemEnum.ExecutiveSummary:
                return "Start-Gpt-ExecutiveSummary";
            case AskAIAssistantMenuItemEnum.WriteSection:
                return "Start-Gpt-WriteSection";
            case AskAIAssistantMenuItemEnum.GenerateReport:
                return "Start-Gpt-GenerateReport";
            case AskAIAssistantMenuItemEnum.Table:
                return "Start-Gpt-Table";
            default:
                return "Start-Gpt-QuestionAndAnswer";
        }
    }

    public getRelatedInsertResultLogEventName(askAIAssistantMenuItem: AskAIAssistantMenuItemEnum): TLogEventName {
        switch (askAIAssistantMenuItem) {
            case AskAIAssistantMenuItemEnum.QuestionAndAnswer:
                return "Insert-Gpt-QuestionAndAnswer-Result";
            case AskAIAssistantMenuItemEnum.GeneralDescriptionUsingLinks:
                return "Insert-Gpt-GeneralDescriptionUsingLinks-Result";
            case AskAIAssistantMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge:
                return "Insert-Gpt-GeneralDescriptionUsingGeneralKnowledge-Result";
            case AskAIAssistantMenuItemEnum.ExecutiveSummary:
                return "Insert-Gpt-ExecutiveSummary-Result";
            case AskAIAssistantMenuItemEnum.WriteSection:
                return "Insert-Gpt-WriteSection-Result";
            case AskAIAssistantMenuItemEnum.GenerateReport:
                return "Insert-Gpt-GenerateReport-Result";
            case AskAIAssistantMenuItemEnum.Table:
                return "Insert-Gpt-Table-Result";
            default:
                return "Insert-Gpt-QuestionAndAnswer-Result";
        }
    }

    public getRelatedErrorMessage(askAIAssistantMenuItem: AskAIAssistantMenuItemEnum): string {
        switch (askAIAssistantMenuItem) {
            case AskAIAssistantMenuItemEnum.GeneralDescription:
            case AskAIAssistantMenuItemEnum.GeneralDescriptionUsingLinks:
            case AskAIAssistantMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge:
                return "Could not generate general description with the provided information, please contact the support.";
            case AskAIAssistantMenuItemEnum.ExecutiveSummary:
                return "Could not generate executive summary with the provided information, please contact the support.";
            case AskAIAssistantMenuItemEnum.Table:
                return "Could not extract detail information with the provided information, please contact the support.";
            case AskAIAssistantMenuItemEnum.GenerateReport:
                return "Could not generate report with the provided information, please contact the support.";
            default:
                return "Something went wrong, please contact the support.";
        }
    }
}


export const AskAIAssistantMenuItemHelperSingleton = new AskAIAssistantMenuItemHelper();