// node_modules
import debounce from "lodash.debounce";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
// Enums
import {
  EntityTypeEnum,
  LinkStatusEnum,
  ObjectTypeEnum,
  OrderByEnum,
  SavedDocumentTypeEnum,
  SortTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Components
import {
  Dropdown,
  EntityMaturityLevels,
  MainTitle,
  ObjectDetails,
  ObjectsRatingsPopover,
  RatingStar,
  TextBoxModal,
} from "Components";
// Contexts
import {
  AuthContext,
  CollaborationContext,
  EditorContext,
  ElementVisibilityContext,
  PinnedContext,
} from "Providers";
// Styles
import commonDropdownStyles from "Styles/Common/dropdown.module.scss";
import entityLikeCardStyles from "Styles/entityLikeCard.module.scss";
// Custom hooks
import { useEntityNameChangeListener, useFetch } from "Hooks";
// Types
import {
  TDocumentsDTO,
  TObjectDetailDTO,
  TObjectsByUserIdDTO,
  TOption,
  TOptions,
  TUseFetch,
} from "Types";
// Controllers
import {
  EntityControllerSingleton,
  ImageControllerSingleton,
  LinkingControllerSingleton,
  RatingControllerSingleton,
  SavedDocumentControllerSingleton,
  TemplateControllerSingleton,
} from "Controllers";
// Helpers
import {
  DocumentTypeHelperSingleton,
  EntityTypeHelperSingleton,
  LogHelperSingleton,
  MarkdownItHelperSingleton,
  ObjectTypeHelperSingleton,
  ProseMirrorHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Constants
import {
  EditorConstants,
  EntityConstants,
  EventConstants,
  GeneralConstants,
} from "Constants";
// Interfaces
import { IEntityDTO, ISavedDocumentDTO } from "Interfaces";

type TEntityDetailsProps = {
  id?: string;
  doIgnoreIsDeleted?: boolean;
  hideReferenceModal?: (callback?: () => void) => void;
};

export const EntityDetails: FC<TEntityDetailsProps> = ({
  id: entityIdFromProps,
  doIgnoreIsDeleted,
  hideReferenceModal,
}: TEntityDetailsProps) => {
  // Hooks
  const { entityId: entityIdFromParams } = useParams();
  const navigate = useNavigate();

  // Constants
  const entityId: string | undefined = entityIdFromProps ?? entityIdFromParams;

  // Contexts
  const { isUserExternal, auth } = useContext(AuthContext);
  const { editorMenuProps, forceUpdateEditorViewSource } =
    useContext(EditorContext);
  const { refreshPins } = useContext(PinnedContext);
  const { isEditModeOn, isEditorShown } = useContext(CollaborationContext);
  const { canUserEdit } = useContext(ElementVisibilityContext);

  // State
  const [currentEntity, setCurrentEntity] = useState<IEntityDTO | undefined>(
    undefined
  );
  const [allEntityTypesDropdownOptions, setAllEntityTypesDropdownOptions] =
    useState<TOptions<EntityTypeEnum>[]>([]);
  const [isCustomEntityTypeModalOpen, setIsCustomEntityTypeModalOpen] =
    useState<boolean>(false);
  const [isObjectsRatingsPopoverOpen, setIsObjectsRatingsPopoverOpen] =
    useState<boolean>(false);
  const [isRatingStarShown, setIsRatingStarShown] = useState<boolean>(false);
  const [averageRating, setAverageRating] = useState<number>(0);
  const [isRatingNeeded, setIsRatingNeeded] = useState<boolean>(false);
  const [ratingStarPopoverRef, setRatingStarPopoverRef] =
    useState<HTMLDivElement | null>(null);

  // Memoized axios parameters
  const axiosParameters = useMemo(() => {
    return { doIgnoreIsDeleted: doIgnoreIsDeleted ? doIgnoreIsDeleted : false };
  }, [doIgnoreIsDeleted]);

  // Memoized is editable
  const isEditable = useMemo(() => {
    return !isUserExternal && !!isEditModeOn && isEditorShown && canUserEdit;
  }, [isUserExternal, isEditModeOn, isEditorShown, canUserEdit]);

  // Retrieve the selected entity
  const { fetchedData: fetchedEntity }: TUseFetch<IEntityDTO> = useFetch(
    `api/entity/${entityId}`,
    axiosParameters
  );

  // Logic
  const handleNewEntityDescriptionAsync = useCallback(
    async (
      entity: IEntityDTO | undefined,
      canEdit: boolean,
      newDescriptionValue: string
    ): Promise<void> => {
      // if the user can not edit or the current entity is not set yet then do nothing
      if (!canEdit || !entity) {
        return;
      }

      // remove me tags
      let newDescriptionValueCopy = `${newDescriptionValue}`;
      newDescriptionValueCopy = newDescriptionValueCopy.replaceAll(
        `${EditorConstants.OPEN_REMOVE_ME_TAG}`,
        ""
      );
      newDescriptionValueCopy = newDescriptionValueCopy.replaceAll(
        `${EditorConstants.CLOSE_REMOVE_ME_TAG}`,
        ""
      );

      // update entity
      setCurrentEntity((oldCurrentEntity) => {
        // safety-checks
        if (!oldCurrentEntity) {
          return oldCurrentEntity;
        }

        // update current entity description
        return {
          ...oldCurrentEntity,
          description: newDescriptionValueCopy,
        };
      });

      // need to post process markdown in order to remove everything between the remove me tags (including the tags)
      newDescriptionValue =
        MarkdownItHelperSingleton.postProcessMarkdown(newDescriptionValue);

      // update description in database
      await EntityControllerSingleton.updateDescriptionAsync(
        entity.id,
        newDescriptionValue
      );

      // log
      LogHelperSingleton.log("UpdateEntityDescription");
    },
    []
  );

  // debounce the handleNewEntityDescriptionAsync function
  const debouncedHandleNewEntityDescriptionAsync = useMemo(
    () =>
      debounce(
        handleNewEntityDescriptionAsync,
        GeneralConstants.DEFAULT_MS_DELAY
      ),
    [handleNewEntityDescriptionAsync]
  );

  const refreshCustomEntityTypesAsync = useCallback(async (): Promise<void> => {
    const allEntityTypeDropdownOptionsGroups =
      await EntityTypeHelperSingleton.getCustomTypeDropdownOptionsGroupAsync(
        true,
        true
      );
    setAllEntityTypesDropdownOptions(allEntityTypeDropdownOptionsGroups);
  }, []);

  useEffect(() => {
    refreshCustomEntityTypesAsync();
  }, [refreshCustomEntityTypesAsync]);

  const onDeleteClickAsync = useCallback(
    async (entity: IEntityDTO | undefined, canEdit: boolean): Promise<void> => {
      // if the user can not edit or the current entity is not set yet then do nothing
      if (!canEdit || !entity || !entity.id) return;

      // confirm with the user that they want to delete the current entity
      if (!confirm(EntityConstants.DELETE_ENTITY_CONFIRMATION)) return;

      if (hideReferenceModal) {
        hideReferenceModal(async () => await deleteEntityAsync(entity.id));
      } else {
        await deleteEntityAsync(entity.id);
      }
    },
    [navigate, refreshPins]
  );

  const deleteEntityAsync = useCallback(async (entityId: string) => {
    // delete the current entity
    const isSuccess = await EntityControllerSingleton.deleteAsync(entityId);

    // indicate failure if needed
    if (!isSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not delete entity."
      );
      return;
    }

    // refresh the pins to make sure deleted pins are gone
    await refreshPins();
  }, []);

  const refreshEntityDocumentsAsync = useCallback(
    async (
      entity: IEntityDTO,
      fromDate: Date | undefined,
      filterOptions: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[],
      sortType: SortTypeEnum,
      callback?: (newSavedDocuments: ISavedDocumentDTO[]) => void,
      doSetTotalDocumentsCount = false
    ): Promise<void> => {
      // get entity saved documents
      const newSavedDocuments: TDocumentsDTO =
        await SavedDocumentControllerSingleton.getObjectSavedDocumentsAsync(
          entity.id,
          ObjectTypeEnum.Entity,
          sortType === SortTypeEnum.Oldest
            ? OrderByEnum.Ascending
            : OrderByEnum.Descending,
          fromDate,
          DocumentTypeHelperSingleton.getSelectedFilterOptions(filterOptions)
        );

      // update entity saved documents
      setCurrentEntity((onCurrentEntity) => {
        // safety-checks
        if (!onCurrentEntity) {
          return onCurrentEntity;
        }

        if (doSetTotalDocumentsCount) {
          onCurrentEntity.totalDocumentsCount = newSavedDocuments.totalCount;
        }

        // update current entity saved documents
        return {
          ...onCurrentEntity,
          savedDocuments: [...newSavedDocuments.documents],
        };
      });

      // if callback is defined then call it
      if (callback) {
        callback(newSavedDocuments.documents);
      }
    },
    []
  );

  const refreshDocumentsAsync = useCallback(
    async (
      fromDate: Date | undefined,
      selectedFilterOptions: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[],
      sortType: SortTypeEnum,
      callback?: ((newSavedDocuments: ISavedDocumentDTO[]) => void) | undefined
    ): Promise<void> => {
      // safety-checks
      if (!currentEntity) {
        // do nothing, return;
        return;
      }

      // call refreshEntityDocumentsAsync
      await refreshEntityDocumentsAsync(
        currentEntity,
        fromDate,
        selectedFilterOptions,
        sortType,
        callback
      );
    },
    [currentEntity, refreshEntityDocumentsAsync]
  );

  const updateEntityRatingsData = useCallback(
    (newEntityRatings: TObjectDetailDTO[]) => {
      // init new average rating
      let newAverageRating = 0;
      // count of rated
      let ratedCount = 0;
      // init new is rating needed
      let newIsRatingNeeded = false;
      newEntityRatings.forEach((newEntityRating: TObjectDetailDTO) => {
        // if newEntityRating.averageRating.score > 0
        if (newEntityRating.averageRating.score > 0) {
          // increment count of rated
          ratedCount++;
          newAverageRating += newEntityRating.averageRating.score;
        }
        newIsRatingNeeded =
          newIsRatingNeeded || !newEntityRating.isRatedByCurrentUser;
      });
      // if rated count > 0
      if (ratedCount > 0) {
        // divide new average rating by rated count
        newAverageRating /= ratedCount;
      }

      // set average rating
      setAverageRating(newAverageRating);
      // set is rating needed
      setIsRatingNeeded(newIsRatingNeeded);
    },
    []
  );

  const refreshEntityRatings = useCallback(
    async (id: string) => {
      // get ratings of entity
      const entityRatings: TObjectsByUserIdDTO | undefined =
        await RatingControllerSingleton.getObjectByUserIdAndTargetIdAsync(
          id,
          ObjectTypeHelperSingleton.getObjectTypeDisplayName(
            ObjectTypeEnum.Entity
          ).toLowerCase()
        );

      // safety-checks
      if (!entityRatings || entityRatings.sources.length === 0) {
        // do not show the rating star
        setIsRatingStarShown(false);
        // do nothing, return
        return;
      }

      // update entity ratings data
      updateEntityRatingsData(entityRatings.sources);

      // show the rating star
      setIsRatingStarShown(true);
    },
    [updateEntityRatingsData]
  );

  useEffect(() => {
    // if the entity is fetched then set it as the current entity
    if (fetchedEntity) {
      setCurrentEntity({
        ...fetchedEntity,
      });

      // refresh entity saved documents
      refreshEntityDocumentsAsync(
        fetchedEntity,
        undefined,
        [],
        SortTypeEnum.Newest,
        undefined,
        true
      );

      // refresh entity ratings
      refreshEntityRatings(fetchedEntity.id);
    }
  }, [fetchedEntity, refreshEntityDocumentsAsync, refreshEntityRatings]);

  const onImageSubmittedAsync = useCallback(
    async (
      entity: IEntityDTO | undefined,
      canEdit: boolean,
      image: File,
      caption?: string
    ): Promise<void> => {
      // if the user can not edit or the current entity is not set yet then do nothing
      if (!canEdit || !entity) return;

      const newImage = await ImageControllerSingleton.addImageToObjectAsync(
        image,
        entity.id,
        ObjectTypeEnum.Entity,
        caption
      );
      if (!newImage) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not add image to entity."
        );
        return;
      }

      setCurrentEntity({
        ...entity,
        images: [...entity.images, newImage],
      });

      editorMenuProps.applyInsertImage(
        newImage.id,
        newImage.path,
        newImage.caption,
        ""
      );
    },
    [editorMenuProps]
  );

  const getEntityTypeTemplateAsync = useCallback(
    async (
      entity: IEntityDTO | undefined,
      oldType: EntityTypeEnum,
      newType: EntityTypeEnum
    ): Promise<void> => {
      // if the current entity is not set yet then do nothing
      if (!entity) {
        return;
      }

      // If the description contains content then do nothing
      if (!ProseMirrorHelperSingleton.isSourceEmpty(entity.description)) {
        // Retrieve the template assiocated with the old entity type
        const template =
          await TemplateControllerSingleton.getEntityTemplateAsync(oldType);
        // Check if the source is exactly the old type template
        if (template !== entity.description) return;
      }

      // Retrieve the template assiocated with the new entity type
      const template =
        await TemplateControllerSingleton.getEntityTemplateAsync(newType);
      // If there is no template do nothing
      if (!template) return;

      // Update the description with the template
      setCurrentEntity({
        ...entity,
        type: newType,
        description: template,
      });
      // update description in database
      await EntityControllerSingleton.updateDescriptionAsync(
        entity.id,
        template
      );
      // Update editor view source
      forceUpdateEditorViewSource(template);
    },
    [forceUpdateEditorViewSource]
  );

  const convertToStudyAsync = useCallback(
    async (entity: IEntityDTO | undefined): Promise<void> => {
      // if the current entity is not set yet then do nothing
      if (!entity || !entity.id) {
        return;
      }

      // Convert the entity to an study
      const isSuccess = await EntityControllerSingleton.convertToStudyAsync(
        entity.id
      );
      if (!isSuccess) {
        // Indicate it to the user if the entity could not be converted to an study
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not convert entity to study."
        );
        return;
      }

      // log
      LogHelperSingleton.log("ConvertEntityToStudy");

      // Refresh the pins
      await refreshPins();

      // Redirect the user to the entity page
      navigate(`/library/studies/${entity.id}`);
    },
    [navigate, refreshPins]
  );

  const saveTypeChangeAsync = useCallback(
    async (
      entity: IEntityDTO | undefined,
      newType: EntityTypeEnum,
      customTypeName?: string
    ): Promise<void> => {
      // if the current entity is not set yet then do nothing
      if (!entity) {
        return;
      }

      // update type in database
      await EntityControllerSingleton.updateTypeAsync(
        entity.id,
        newType,
        customTypeName
      );

      // update type
      setCurrentEntity({
        ...entity,
        type: newType,
        customTypeName,
      });

      // Refresh the custom entity types
      await refreshCustomEntityTypesAsync();
    },
    [refreshCustomEntityTypesAsync]
  );

  const updateTypeAsync = useCallback(
    async (
      entity: IEntityDTO | undefined,
      canEdit: boolean,
      option: TOption<EntityTypeEnum>
    ): Promise<void> => {
      // if the user can not edit or the current entity is not set yet then do nothing
      if (!canEdit || !entity) return;

      // if create custom type is selected then prompt the user for the custom type name
      if (option.title === EntityConstants.CREATE_CUSTOM_TYPE_OPTION) {
        setIsCustomEntityTypeModalOpen(true);
        return;
      } else if (option.title === EntityConstants.CONVERT_TO_STUDY_OPTION) {
        convertToStudyAsync(entity);
        return;
      }

      // check if a custom type is selected
      const customTypeName: string | undefined =
        option.value === EntityTypeEnum.Custom ? option.title : undefined;

      // save the types changes
      const oldEntityType = entity.type;
      await saveTypeChangeAsync(entity, option.value, customTypeName);
      await getEntityTypeTemplateAsync(entity, oldEntityType, option.value);

      // log
      LogHelperSingleton.log("ChangeEntityType");
    },
    [convertToStudyAsync, getEntityTypeTemplateAsync, saveTypeChangeAsync]
  );

  const deleteDocumentAsync = useCallback(
    async (
      entity: IEntityDTO | undefined,
      canEdit: boolean,
      documentsToDelete: ISavedDocumentDTO[]
    ): Promise<void> => {
      // if the user can not edit or the current entity is not set yet then do nothing
      if (!canEdit || !entity) return;

      // delete links between document and entity
      const isSuccess = await LinkingControllerSingleton.deleteBulkAsync(
        entity.id,
        ObjectTypeEnum.Entity,
        documentsToDelete.map((document) => document.id),
        ObjectTypeEnum.Document
      );

      // safety-checks
      if (!isSuccess) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not delete selected documents."
        );
        return;
      }

      // log
      LogHelperSingleton.log("RemoveDocument(s)FromEntity");
    },
    []
  );

  const handleNewEntityTitleAsync = useCallback(
    async (
      entity: IEntityDTO | undefined,
      canEdit: boolean,
      newTitle: string
    ): Promise<void> => {
      // if the user can not edit then do nothing or the current entity is not set yet then do nothing
      if (!canEdit || !entity) {
        return;
      }

      // update title in context
      setCurrentEntity({
        ...entity,
        title: newTitle,
      });

      // update title in database
      await EntityControllerSingleton.updateTitleAsync(entity.id, newTitle);

      // log
      LogHelperSingleton.log("UpdateEntityTitle");
    },
    []
  );

  // debounce the handleNewEntityTitleAsync function
  const debouncedHandleNewEntityTitleAsync = useMemo(
    () =>
      debounce(
        handleNewEntityTitleAsync,
        EventConstants.UPDATE_OBJECT_NAME_DEFAULT_MS_DELAY
      ),
    [handleNewEntityTitleAsync]
  );

  const onCreateCustomEntityTypeAsync = useCallback(
    async (
      entity: IEntityDTO | undefined,
      customTypeName: string
    ): Promise<void> => {
      // check if the custom entity type has a value
      if (customTypeName.trim().length === 0) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Please provide a value for the custom type name."
        );
        return;
      }

      // log
      LogHelperSingleton.log("CustomEntityType-CreateFromDropdown");

      // save the types changes including the custom type name
      await saveTypeChangeAsync(entity, EntityTypeEnum.Custom, customTypeName);

      // close the create entity type modal
      setIsCustomEntityTypeModalOpen(false);
    },
    [saveTypeChangeAsync]
  );

  // Hooks live update the Entities name
  useEntityNameChangeListener(undefined, setCurrentEntity);

  // if the entity id is not defined then navigate to the entities list
  if (!entityId) {
    navigate("/library/entities/");
    return null;
  }

  // if the entity is not fetched then show nothing
  if (!currentEntity) return <div></div>;

  // Render
  return (
    <>
      <ObjectDetails
        objectType={ObjectTypeEnum.Entity}
        object={currentEntity}
        setObject={setCurrentEntity}
        type={EntityTypeHelperSingleton.getEntityTypeDisplayName(
          currentEntity.type,
          currentEntity.customTypeName
        )}
        onDeleteClickAsync={() => onDeleteClickAsync(currentEntity, isEditable)}
        onImageSubmittedAsync={(image: File, caption?: string) =>
          onImageSubmittedAsync(currentEntity, isEditable, image, caption)
        }
        noSourcePlaceholder="Welcome to this page! Here you can create and organize this page’s content by adding headers, format the text and add images from the top bar. From the right sidebar you can add references, highlights and images from linked documents. Have fun creating!"
        onSourceChange={(newValue: string) =>
          debouncedHandleNewEntityDescriptionAsync(
            currentEntity,
            isEditable,
            newValue
          )
        }
        refreshDocumentsAsync={refreshDocumentsAsync}
        deleteSavedDocumentAsync={
          !isEditable
            ? undefined
            : (savedDocumentsToDelete: ISavedDocumentDTO[]) =>
                deleteDocumentAsync(
                  currentEntity,
                  isEditable,
                  savedDocumentsToDelete
                )
        }
      >
        <div
          className={`${entityLikeCardStyles.entityLikeCardHeaderContainer} ${entityLikeCardStyles.hasBottomContent}`}
        >
          <div
            className={
              entityLikeCardStyles.entityLikeCardHeaderContainerTopContent
            }
          >
            <Dropdown
              isEditable={isEditable}
              selectedOption={{
                value: currentEntity.type,
                title: EntityTypeHelperSingleton.getEntityTypeDisplayName(
                  currentEntity.type,
                  currentEntity.customTypeName
                ),
              }}
              handleOptionSelect={(option: TOption<EntityTypeEnum>) =>
                updateTypeAsync(currentEntity, isEditable, option)
              }
              options={allEntityTypesDropdownOptions}
              placeholderText="Select entity type"
              className={commonDropdownStyles.commonDropdown}
              classNameSelect={`${commonDropdownStyles.grayDropdownSelect} ${entityLikeCardStyles.objectTypeDropdown}`}
              leftIconProps={{
                icon: ObjectTypeHelperSingleton.getObjectTypeIcon(
                  ObjectTypeEnum.Entity
                ),
                className: `${entityLikeCardStyles.objectTypeIcon} ${entityLikeCardStyles[ObjectTypeEnum.Entity]}`,
              }}
            />
          </div>
          <div className={entityLikeCardStyles.entityLikeTitleContainer}>
            <MainTitle
              showFullTitleOnHoverOnTooltip
              shouldEditableInputAutoGrow
              title={currentEntity.title}
              isEditable={isEditable}
              onUpdateTitle={(newTitle: string) =>
                debouncedHandleNewEntityTitleAsync(
                  currentEntity,
                  isEditable,
                  newTitle
                )
              }
            />
            <div
              ref={setRatingStarPopoverRef}
              className={entityLikeCardStyles.ratingStarContainer}
            >
              {isRatingStarShown && (
                <RatingStar
                  rating={averageRating}
                  isRatingNeeded={isRatingNeeded}
                  isRatingShown={true}
                  size="xlarge"
                  onMouseOverHandler={() => {
                    setIsObjectsRatingsPopoverOpen(true);
                  }}
                  onMouseOutHandler={() => {
                    setIsObjectsRatingsPopoverOpen(false);
                  }}
                />
              )}
            </div>
            <ObjectsRatingsPopover
              isOpen={isObjectsRatingsPopoverOpen}
              objectId={currentEntity.id}
              objectType={ObjectTypeEnum.Entity}
              currentUserEmail={auth.userEmail}
              ratingStarRef={ratingStarPopoverRef}
              onNewAverageRating={updateEntityRatingsData}
              onMouseEnter={() => {
                setIsObjectsRatingsPopoverOpen(true);
              }}
              onMouseLeave={() => {
                setIsObjectsRatingsPopoverOpen(false);
              }}
            />
            <EntityMaturityLevels
              entityId={currentEntity.id}
              extraClassNames={{
                container: entityLikeCardStyles.maturityLevelContainer,
              }}
            />
          </div>
        </div>
      </ObjectDetails>
      <TextBoxModal
        isOpen={isCustomEntityTypeModalOpen}
        setIsOpen={setIsCustomEntityTypeModalOpen}
        onSaveButtonClick={(textValue: string) =>
          onCreateCustomEntityTypeAsync(currentEntity, textValue)
        }
        placeHolder={"Custom entity type name"}
        textName="Custom entity type name"
        title="Create custom entity type"
      />
    </>
  );
};
