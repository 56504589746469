// node_modules
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Contexts
import { CollaborationContext } from "Providers";
// Controllers
import {
  LinkingControllerSingleton,
  MaturityRadarControllerSingleton,
  RequirementsTableControllerSingleton,
} from "Controllers";
// Helpers
import {
  ScoutingServiceTableHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Types
import {
  TInitialScoutingServiceTableProps,
  TLinkGraphDTO,
  TMaturityRadarDTO,
  TRequirementsTableDTO,
} from "Types";
// Custom hooks
import { useAnyLinkAddedListener, useAnyLinkRemovedListener } from "Hooks";

type TLinksContext = {
  linkGraphForObjectEdited?: TLinkGraphDTO;
  maturityRadar?: TMaturityRadarDTO;
  initialMaturityRadarProps?: TInitialScoutingServiceTableProps;
  setMaturityRadar: (newMaturityRadar: TMaturityRadarDTO | undefined) => void;
  refreshMaturityRadarAsync: (
    forObjectId: string,
    forObjectType: ObjectTypeEnum,
    currentLinkGraphForObjectEdited: TLinkGraphDTO
  ) => Promise<void>;
  requirementsTable?: TRequirementsTableDTO;
  initialRequirementsTableProps?: TInitialScoutingServiceTableProps;
  setRequirementsTable: (
    newRequirementsTable: TRequirementsTableDTO | undefined
  ) => void;
  refreshRequirementsTableAsync: (
    forObjectId: string,
    forObjectType: ObjectTypeEnum,
    currentLinkGraphForObjectEdited: TLinkGraphDTO
  ) => Promise<void>;
};

export const defaultLinksContext: TLinksContext = {
  linkGraphForObjectEdited: undefined,
  maturityRadar: undefined,
  initialMaturityRadarProps: undefined,
  setMaturityRadar: () => null,
  refreshMaturityRadarAsync: () => Promise.resolve(),
  requirementsTable: undefined,
  initialRequirementsTableProps: undefined,
  setRequirementsTable: () => null,
  refreshRequirementsTableAsync: () => Promise.resolve(),
};

type TLinksProviderProps = {
  children?: ReactNode;
};

export const LinksContext = createContext<TLinksContext>({
  ...defaultLinksContext,
});

export const LinksProvider = ({ children }: TLinksProviderProps) => {
  // State
  const [linkGraphForObjectEdited, setLinkGraphForObjectEdited] = useState<
    TLinkGraphDTO | undefined
  >(undefined);
  const [maturityRadar, setMaturityRadar] = useState<
    TMaturityRadarDTO | undefined
  >(undefined);
  const [initialMaturityRadarProps, setInitialMaturityRadarProps] = useState<
    TInitialScoutingServiceTableProps | undefined
  >(undefined);
  const [requirementsTable, setRequirementsTable] = useState<
    TRequirementsTableDTO | undefined
  >(undefined);
  const [initialRequirementsTableProps, setInitialRequirementsTableProps] =
    useState<TInitialScoutingServiceTableProps | undefined>(undefined);

  // Contexts
  const { objectIdEdited, objectTypeEdited } = useContext(CollaborationContext);

  // Logic
  const getLinkGraphForObjectEditedAsync = useCallback(
    async (
      forObjectIdEdited: string,
      forObjectTypeEdited: ObjectTypeEnum
    ): Promise<TLinkGraphDTO | undefined> => {
      // get maximum lower levels
      const maximumLowerLevels: number =
        await LinkingControllerSingleton.getMaximumLowerLevelsAsync(
          forObjectIdEdited
        );

      // get link graph async for the edited object
      const newLinkGraphForObjectEdited: TLinkGraphDTO | undefined =
        await LinkingControllerSingleton.getLinkGraphAsync(
          forObjectIdEdited,
          forObjectTypeEdited,
          maximumLowerLevels,
          true
        );

      // return new link graph for object edited
      return newLinkGraphForObjectEdited;
    },
    []
  );

  // refresh link graph for object edited
  const refreshLinkGraphForObjectEditedAsync = useCallback(
    async (
      forObjectIdEdited?: string,
      forObjectTypeEdited?: ObjectTypeEnum
    ): Promise<void> => {
      // safety-checks
      if (!forObjectIdEdited || !forObjectTypeEdited) {
        // set link graph for object edited to undefined
        setLinkGraphForObjectEdited(undefined);
        // stop execution, return
        return;
      }

      // get link graph for object edited
      const newLinkGraphForObjectEdited: TLinkGraphDTO | undefined =
        await getLinkGraphForObjectEditedAsync(
          forObjectIdEdited,
          forObjectTypeEdited
        );

      // set link graph for object edited
      setLinkGraphForObjectEdited(newLinkGraphForObjectEdited);
    },
    [getLinkGraphForObjectEditedAsync]
  );

  const getInitialMaturityRadarPropsAsync = useCallback(
    async (
      currentLinkGraphForObjectEdited: TLinkGraphDTO,
      currentMaturityRadar: TMaturityRadarDTO | undefined
    ): Promise<TInitialScoutingServiceTableProps | undefined> => {
      // safety-checks
      if (
        !currentMaturityRadar ||
        !currentMaturityRadar.assessments ||
        currentMaturityRadar.assessments.length < 1
      ) {
        // return undefined
        return undefined;
      }

      // build initial scouting service table props from maturity radar
      return ScoutingServiceTableHelperSingleton.buildInitialScoutingServiceTablePropsFromMaturityRadar(
        currentLinkGraphForObjectEdited,
        currentMaturityRadar
      );
    },
    []
  );

  const refreshMaturityRadarAsync = useCallback(
    async (
      forObjectId: string,
      forObjectType: ObjectTypeEnum,
      currentLinkGraphForObjectEdited: TLinkGraphDTO
    ): Promise<void> => {
      // get new maturity radar
      const newMaturityRadar: TMaturityRadarDTO | undefined =
        await MaturityRadarControllerSingleton.getAsync(
          forObjectId,
          forObjectType
        );

      // safety-checks
      if (!newMaturityRadar) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not get maturity radar."
        );
      }

      // set new maturity radar
      setMaturityRadar(newMaturityRadar);

      // get new initial maturity radar props
      const newInitialMaturityRadarProps:
        | TInitialScoutingServiceTableProps
        | undefined = await getInitialMaturityRadarPropsAsync(
        currentLinkGraphForObjectEdited,
        newMaturityRadar
      );

      // set new initial maturity radar props
      setInitialMaturityRadarProps(newInitialMaturityRadarProps);
    },
    [getInitialMaturityRadarPropsAsync]
  );

  const getInitialRequirementsTablePropsAsync = useCallback(
    async (
      currentLinkGraphForObjectEdited: TLinkGraphDTO,
      currentRequirementsTable: TRequirementsTableDTO | undefined
    ): Promise<TInitialScoutingServiceTableProps | undefined> => {
      // safety-checks
      if (
        !currentRequirementsTable ||
        !currentRequirementsTable.tableRows ||
        currentRequirementsTable.tableRows.length < 1
      ) {
        // return undefined
        return undefined;
      }

      // build initial scouting service table props from requirements table
      return ScoutingServiceTableHelperSingleton.buildInitialScoutingServiceTablePropsFromRequirementsTable(
        currentLinkGraphForObjectEdited,
        currentRequirementsTable
      );
    },
    []
  );

  // refresh requirements table
  const refreshRequirementsTableAsync = useCallback(
    async (
      forObjectId: string,
      forObjectType: ObjectTypeEnum,
      currentLinkGraphForObjectEdited: TLinkGraphDTO
    ) => {
      // get new requirements table
      const newRequirementsTable: TRequirementsTableDTO | undefined =
        await RequirementsTableControllerSingleton.getAsync(
          forObjectType,
          forObjectId
        );

      // safety-checks
      if (!newRequirementsTable) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not get requirements table."
        );
      }

      // set new requirements table
      setRequirementsTable(newRequirementsTable);

      // get new initial requirements table props
      const newInitialRequirementsTableProps:
        | TInitialScoutingServiceTableProps
        | undefined = await getInitialRequirementsTablePropsAsync(
        currentLinkGraphForObjectEdited,
        newRequirementsTable
      );

      // set new initial requirements table props
      setInitialRequirementsTableProps(newInitialRequirementsTableProps);
    },
    [getInitialRequirementsTablePropsAsync]
  );

  // Memo
  const linksProviderValue = useMemo((): TLinksContext => {
    return {
      linkGraphForObjectEdited,
      maturityRadar,
      initialMaturityRadarProps,
      setMaturityRadar,
      refreshMaturityRadarAsync,
      requirementsTable,
      initialRequirementsTableProps,
      setRequirementsTable,
      refreshRequirementsTableAsync,
    };
  }, [
    initialMaturityRadarProps,
    linkGraphForObjectEdited,
    maturityRadar,
    refreshMaturityRadarAsync,
    refreshRequirementsTableAsync,
    initialRequirementsTableProps,
    requirementsTable,
  ]);

  // when object id or type changes
  useEffect(() => {
    // otherwise
    (async () => {
      // refresh link graph for object edited
      await refreshLinkGraphForObjectEditedAsync(
        objectIdEdited,
        objectTypeEdited
      );
    })();
  }, [objectIdEdited, objectTypeEdited, refreshLinkGraphForObjectEditedAsync]);

  const onAnyLinkAddedHandler = useCallback(async () => {
    // refresh link graph for object edited when any link is added (could be anywhere in its tree)
    await refreshLinkGraphForObjectEditedAsync(
      objectIdEdited,
      objectTypeEdited
    );
  }, [objectIdEdited, objectTypeEdited, refreshLinkGraphForObjectEditedAsync]);

  const onAnyLinkRemovedHandler = useCallback(async () => {
    // refresh link graph for object edited when any link is removed (could be anywhere in its tree)
    await refreshLinkGraphForObjectEditedAsync(
      objectIdEdited,
      objectTypeEdited
    );
  }, [objectIdEdited, objectTypeEdited, refreshLinkGraphForObjectEditedAsync]);

  // attach listeners to any link added or removed
  useAnyLinkAddedListener(onAnyLinkAddedHandler);
  useAnyLinkRemovedListener(onAnyLinkRemovedHandler);

  // Render
  return (
    <LinksContext.Provider value={linksProviderValue}>
      {children}
    </LinksContext.Provider>
  );
};
