// node_modules
import {
  faBookOpenReader,
  faDatabase,
  faDiceD6,
  faFile,
  faQuestion,
  faSearch,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import {
  EntityControllerSingleton,
  StudyControllerSingleton,
} from "Controllers";
import { NavigateFunction } from "react-router";
// Enums
import {
  EntityTypeEnum,
  ObjectTypeEnum,
  SavedDocumentTypeEnum,
  StudyTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Helpers
import { LogHelperSingleton, ToastHelperSingleton } from "Helpers";
// Interfaces
import { IEntityDTO } from "Interfaces";
// Types
import { TDropdownButtonOption, TIdNameTypeObjectType } from "Types";

export class ObjectTypeHelper {
  public getObjectTypeReactRouteName(objectType: ObjectTypeEnum): string {
    switch (objectType) {
      case ObjectTypeEnum.Entity:
        return "entities";
      case ObjectTypeEnum.Study:
        return "studies";
      case ObjectTypeEnum.Query:
        return "queries";
      case ObjectTypeEnum.MagPatent:
      case ObjectTypeEnum.UsPatent:
      case ObjectTypeEnum.ScienceArticle:
      case ObjectTypeEnum.Weblink:
      case ObjectTypeEnum.Highlight:
      default:
        return "";
    }
  }

  public getObjectTypeEndpointName(objectType: ObjectTypeEnum): string {
    switch (objectType) {
      case ObjectTypeEnum.Entity:
        return "Entity";
      case ObjectTypeEnum.Query:
        return "Query";
      case ObjectTypeEnum.MagPatent:
      case ObjectTypeEnum.UsPatent:
      case ObjectTypeEnum.ScienceArticle:
      case ObjectTypeEnum.Weblink:
        return "Document";
      case ObjectTypeEnum.Highlight:
        return "Highlight";
      case ObjectTypeEnum.Study:
        return "Study";
      default:
        return "";
    }
  }

  public getObjectTypeDropdownButtonAction(
    objectType: ObjectTypeEnum
  ): TDropdownButtonOption {
    switch (objectType) {
      case ObjectTypeEnum.Entity:
        return "entity";
      case ObjectTypeEnum.Study:
        return "study";
      default:
        return "";
    }
  }

  public getObjectTypeIcon(objectType: ObjectTypeEnum): IconDefinition {
    switch (objectType) {
      case ObjectTypeEnum.Entity:
        return faDiceD6;
      case ObjectTypeEnum.Study:
        return faBookOpenReader;
      case ObjectTypeEnum.Query:
        return faSearch;
      case ObjectTypeEnum.Document:
      case ObjectTypeEnum.ScienceArticle:
      case ObjectTypeEnum.MagPatent:
      case ObjectTypeEnum.Weblink:
      case ObjectTypeEnum.UsPatent:
        return faFile;
      case ObjectTypeEnum.Tenant:
        return faDatabase;
      default:
        return faQuestion;
    }
  }

  /** Returns the correct icon class name depending on the object type. */
  public getObjectTypeIconClassName = (type: ObjectTypeEnum): string => {
    switch (type) {
      case ObjectTypeEnum.Entity:
        return "fa-solid fa-dice-d6";
      case ObjectTypeEnum.Study:
        return "fa-solid fa-book-open-reader";
      default:
        return "fa-solid fa-question";
    }
  };

  public getObjectTypeColor(objectType: ObjectTypeEnum): string {
    switch (objectType) {
      case ObjectTypeEnum.Entity:
        return "#00ADEF";
      case ObjectTypeEnum.Study:
        return "#5856D6";
      case ObjectTypeEnum.Query:
        return "#A30076";
      default:
        return "#00ADEF";
    }
  }

  public documentTypeToObjectType(
    documentType: SavedDocumentTypeEnum
  ): ObjectTypeEnum {
    switch (documentType) {
      case SavedDocumentTypeEnum.ScienceArticle:
        return ObjectTypeEnum.ScienceArticle;
      case SavedDocumentTypeEnum.MagPatent:
        return ObjectTypeEnum.MagPatent;
      case SavedDocumentTypeEnum.UsPatent:
        return ObjectTypeEnum.UsPatent;
      case SavedDocumentTypeEnum.Weblink:
        return ObjectTypeEnum.Weblink;
      default:
        return ObjectTypeEnum.Unknown;
    }
  }

  public getObjectTypeDisplayName(objectType: ObjectTypeEnum): string {
    switch (objectType) {
      case ObjectTypeEnum.Case:
        return "Case";
      case ObjectTypeEnum.Entity:
        return "Entity";
      case ObjectTypeEnum.Highlight:
        return "Highlight";
      case ObjectTypeEnum.MagPatent:
        return "Patent";
      case ObjectTypeEnum.ScienceArticle:
        return "Science article";
      case ObjectTypeEnum.Study:
        return "Study";
      case ObjectTypeEnum.UsPatent:
        return "Patent";
      case ObjectTypeEnum.Weblink:
        return "Webpage";
      case ObjectTypeEnum.Comment:
        return "Comment";
      case ObjectTypeEnum.Document:
        return "Document";
      case ObjectTypeEnum.Unknown:
        return "Unknown";
      case ObjectTypeEnum.File:
        return "File";
      case ObjectTypeEnum.Image:
        return "Image";
      case ObjectTypeEnum.Organization:
        return "Organization";
      case ObjectTypeEnum.Query:
        return "Query";
      case ObjectTypeEnum.Tenant:
        return "Tenant";
      default:
        return "No object type";
    }
  }

  public getObjectTypeFullDisplayName(
    objectType: ObjectTypeEnum,
    typeName?: string,
    customTypeName?: string
  ): string {
    if (objectType === ObjectTypeEnum.Study) {
      if (customTypeName) {
        return customTypeName;
      } else if (typeName) {
        if (typeName !== "Study") return typeName;
      }
    } else if (objectType === ObjectTypeEnum.Entity) {
      if (customTypeName) {
        return customTypeName;
      } else if (typeName) {
        if (typeName !== "Entity") return typeName;
      }
    }

    return this.getObjectTypeDisplayName(objectType);
  }

  public navigateBasedOnObjectType(
    objectType: ObjectTypeEnum,
    objectId: string,
    navigate: NavigateFunction
  ): void {
    navigate(this.getNavigateUrlBasedOnObjectType(objectType, objectId));
  }

  public getNavigateUrlBasedOnObjectType(
    objectType: ObjectTypeEnum,
    objectId: string
  ): string {
    if (objectType === ObjectTypeEnum.Entity) {
      return `/library/entities/${objectId}`;
    } else if (objectType === ObjectTypeEnum.Study) {
      return `/library/studies/${objectId}`;
    } else if (objectType === ObjectTypeEnum.Document) {
      return `/library/documents/${objectId}`;
    } else {
      throw new Error("Unknown object type.");
    }
  }

  public async createObject(
    option: string,
    objectName: string
  ): Promise<TIdNameTypeObjectType | undefined> {
    // init created object
    let createdObject: TIdNameTypeObjectType | undefined = undefined;
    // lowercase option
    option = option.toLowerCase();

    if (option === "entity") {
      const createdEntity = await EntityControllerSingleton.createAsync({
        title: objectName,
        type: EntityTypeEnum.Undefined,
      } as IEntityDTO);

      if (!createdEntity) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not create entity."
        );
        return;
      }

      // log
      LogHelperSingleton.log("StructureGraphLinkFromModal");
      LogHelperSingleton.log("CreatedEntityFromLinkingModal");

      // set created object
      createdObject = {
        id: createdEntity.id,
        name: createdEntity.title,
        objectType: ObjectTypeEnum.Entity,
        type: "Entity",
        customTypeName: createdEntity.customTypeName,
        isConnected: undefined,
        url: `/library/entities/${createdEntity.id}`,
      };
    } else if (option === "study") {
      const createdStudy = await StudyControllerSingleton.createAsync(
        objectName,
        "",
        StudyTypeEnum.Undefined
      );

      if (!createdStudy) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not create study."
        );
        return;
      }

      // log
      LogHelperSingleton.log("StructureGraphLinkFromModal");
      LogHelperSingleton.log("CreatedStudyFromLinkingModal");

      // set created object
      createdObject = {
        id: createdStudy.id,
        name: createdStudy.title,
        objectType: ObjectTypeEnum.Study,
        type: "Study",
        customTypeName: createdStudy.customTypeName,
        isConnected: undefined,
        url: `/library/studies/${createdStudy.id}`,
      };
    }

    // return created object
    return createdObject;
  }
}

export const ObjectTypeHelperSingleton = new ObjectTypeHelper();
